var map; // finally, map is global var;)

function new_map( $el ) {

	var $markers = $el.find('.marker');

	var args = {
		zoom: 14,
		center: new google.maps.LatLng(0, 0),
		draggable: false,
		scrollwheel: false,
		disableDoubleClickZoom: true,
		panControl: false,
		zoomControl: true,
		scaleControl: false,
		mapTypeControl: false,
		streetViewControl: false,
		overviewMapControl: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	};

	map = new google.maps.Map( $el[0], args);

	map.markers = [];

	var styles = [{
		featureType: "all",
		elementType: "all",
		stylers: [{ saturation: -100} ]
	}];

	var styledMapOptions = {
		map: map
	}

	var mainmap = new google.maps.StyledMapType(styles,styledMapOptions);

	map.mapTypes.set('site_map', mainmap);
	map.setMapTypeId('site_map');

	$markers.each(function(){
    	add_marker( jQuery(this), map );
	});

	center_map( map );

	return map;
}
function add_marker( $marker, map ) {
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

	var image = new google.maps.MarkerImage(
		'/static/img/marker.png',
		new google.maps.Size(46,56),
		new google.maps.Point(0,0),
		new google.maps.Point(23,56)
	);

	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map,
		icon		: image
	});

	map.markers.push( marker );

	if( $marker.html() ) {
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});
		google.maps.event.addListener(marker, 'click', function() {
			infowindow.open( map, marker );
		});
	}
}
function center_map( map ) {
	var bounds = new google.maps.LatLngBounds();

	jQuery.each( map.markers, function( i, marker ){
		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
		bounds.extend( latlng );
	});
	if( map.markers.length == 1 ) {
		map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else {
		map.fitBounds( bounds );
	}
}

jQuery(document).ready(function($){
	$('.gmap').each(function(){
		map = new_map( $(this) );
	});
});