$(document).ready(function ($) {

	// Fix nav
	$(window).on('load scroll',function() {
		var scrollTop = $(window).scrollTop();

		if ( scrollTop > 50 ) {
			$('.page-home .fix-actions').addClass('hidden');
		}
		else {
			$('.page-home .fix-actions').removeClass('hidden');
		}
	});

	// Match height
	$('.item-eq').matchHeight();

	$(window).load(function() {
		$('.fancy-gallery .item-list').masonry({
		  	itemSelector: '.item',
		  	columnWidth: '.item',
	  		percentPosition: true
		  	//columnWidth: 200
		});
	});

	// Points
	/*$('.points .item').each(function(){
		var item = $(this);
		var trigger = item.find('.trigger');
		var content = item.find('.content');

		trigger.click(function() {
			if ( ! item.hasClass('active') ) {
				$('.points .item').removeClass('active');
				item.addClass('active');
			}
			else {
				item.removeClass('active');
			}
		});
	});*/

	/**********************************************
	DATEPICKER
	**********************************************/

	$(function($){
        $.datepicker.regional['cs'] = {
			renderer: $.ui.datepicker.defaultRenderer,
			monthNames: ['leden','únor','březen','duben','květen','červen','červenec','srpen','září','říjen','listopad','prosinec'],
			monthNamesShort: ['led','úno','bře','dub','kvě','čer',
			'čvc','srp','zář','říj','lis','pro'],
			dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
			dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
			dayNamesMin: ['ne','po','út','st','čt','pá','so'],
			dateFormat: 'dd.mm.yyyy',
			firstDay: 1,
			prevText: '&#x3c;Dříve', prevStatus: '',
			prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: '',
			nextText: 'Později&#x3e;', nextStatus: '',
			nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
			currentText: 'Nyní', currentStatus: '',
			todayText: 'Nyní', todayStatus: '',
			clearText: '-', clearStatus: '',
			closeText: 'Zavřít', closeStatus: '',
			yearStatus: '', monthStatus: '',
			weekText: 'Týd', weekStatus: '',
			dayStatus: 'DD d MM',
			defaultStatus: '',
			isRTL: false,
			onClose: function() {
				$(this).blur();
			}
		};
        $.datepicker.setDefaults($.datepicker.regional['cs']);
	});

	$(function() {
        $(".input-datepicker").datepicker({
           	/*showOn: "button",
            buttonImage: "/fileadmin/templates/images/datepicker.png",
            buttonImageOnly: true,*/
			dateFormat: 'd. m. yy'
        });
    });

	/**********************************************
	MOBILE NAVIGATION
	**********************************************/

	$(function() {
		var mobileNav = $('.mobile-nav');
		var mobileNavOverlay = $('.mobile-nav-overlay');

		function showNav() {
			mobileNav.addClass('active');
			mobileNavOverlay.fadeIn();
			//$('body').css({ 'overflow': 'hidden' });
		}

		function hideNav() {
			mobileNav.removeClass('active');
			mobileNavOverlay.fadeOut();
			//$('body').css({ 'overflow': 'auto' });
		}

		$('.nav-btn').click(function() {
			if ( ! mobileNav.hasClass('active') ) {
				showNav();
			}
			else {
				hideNav();
			}
			return false;
		});

		// Mobile nav close
		$('.mobile-nav .close').click(function(){
			hideNav();
		});

		// Mobile nav overlay close
		$('.mobile-nav-overlay').click(function(){
			hideNav();
		});

		$('.mobile-nav li a').each(function() {
			if ( $(this).siblings('ul').length > 0 ) {
				$(this).append('<span class="more"></span>');
			}
		});

		// Mobile nav accordion
		$('.mobile-nav li a').click(function() {
			if ( $(this).siblings('ul').length > 0 ) {
				if ( ! $(this).siblings('ul').hasClass('active') ) {
					//$('.mobile-nav ul ul').removeClass('active');
					$(this).siblings('ul').addClass('active');
				} else {
					$(this).siblings('ul').removeClass('active');
				}
				return false;
			}
		});
	});

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

});

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}
